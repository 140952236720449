@import "../variables";

.btn {
  display: inline-block;
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  text-decoration: none;
  box-sizing: border-box;

  &--default {
    font-size: 12px;
    color: #494949;
    padding: 8px 20px;
    border-width: 1px;
    border-color: #d1d1d2;
    border-style: solid;
    transition: background .2s, color .2s;

    @media ($desktop) {
      font-size: 15px;
      padding: 11px 23px;
    }
    
    &:hover {
      background-color: #494949;
      color: #fff;
    }
  }

  &--approve {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $fontBold;
    font-size: 11px;
    color: #fff;
    background-color: #00963f;
    padding: 10px 10px;
    border: 1px solid #55b67e;
  }

  &--catalog {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00963f;
    padding: 10px 10px;
    border: 1px solid #55b67e;
    color: #fff;
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-weight: 700;
    font-size: 12px;
  }

  &--secondary {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 12px;
    color: #fff;
    padding: 10px 10px;
    background-color: #494949;
  }
}
