@import "../variables";

.review {
  margin: 30px 0;
}

.review__header {
  display: flex;
  align-items: center;
  margin-bottom: 25px;

  .title {
    margin-bottom: 0;
    margin-right: 20px;
  }

  sup {
    font-family: $fontBold;
    font-size: 8px;
    color: #a5a5a5;

    @media ($tablet) {
      font-size: 15px;
      margin-top: -10px;
    }
  }

  .btn {
    font-size: 9px;

    @media ($tablet) {
      display: none;
    }
  }
}

.review__total-wrapper {
  border: 2px solid #dddddd;

  @media ($tablet) {
    display: none;
  }
}

.review__sum {
  display: none;

  @media ($tablet) {
    display: flex;
    align-items: center;
  }
}

.review__sum-title {
  font-family: $fontRegular;
  font-size: 15px;
  color: #494949;
  margin: 0 20px 0 0;
}

.review__sum-stars {
  font-family: $fontRegular;
  display: flex;
  align-items: center;
  font-size: 15px;
  color: #00963f;
  margin-right: 20px;
  margin-top: 0;
  margin-bottom: 0;

  span {
    margin-right: 20px;
  }
}

.review__sum-star {
  width: 10px;
  height: 10px;
  fill: #00963f;
  margin: 0 3px;
}

.review__sum-error {
  font-size: 14px;
  color: #e3000f;
  margin: 0 0 0 30px;
  width: 220px;
}

.review__sum-button {
  padding: 12px 20px;
}

.review__rating-wrapper {
  display: flex;
  align-items: center;
}

.review__rating {
  text-align: center;
  background-color: #dddddd;
  padding: 10px;
}

.review__rating-count {
  margin: 0;
  font-family: $fontBold;
  font-size: 29px;
  color: #494949;
}

.review__rating-stars {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 0 0;
}

.review__rating-star {
  width: 8px;
  height: 7px;
  margin: 0 2px;
  fill: #00963f;
}

.review__calc {
  margin: 0 0 0 15px;
  font-size: 10px;
  color: #494949;
  width: 150px;
}

.review__rating-param {
  padding: 20px;
}

.review__rating-title {
  font-family: $fontBold;
  font-size: 10px;
  color: #494949;
  margin: 0 0 7px;
  text-transform: uppercase;
}

.review__rating-label {
  font-family: $fontBold;
  font-size: 9px;
  color: #494949;
  margin: 0 0 5px;
}

.review__scale-wrapper {
  width: 175px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.review__scale {
  width: 100%;
  height: 3px;
  background-color: #d1d1d1;
  position: relative;
  margin-bottom: 3px;
}

.review__scale-done {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #00963f;
  height: 3px;
}

.review__scale-title {
  font-size: 8px;
  color: #494949;
}

.review__item {
  padding: 15px 10px;
  border-bottom: 1px solid #dadada;

  @media ($tablet) {
    padding: 15px 20px;
    background-color: #f8fafb;
    margin-top: 15px;
    position: relative;
  }
}

.review__item-top {
  margin-bottom: 15px;

  @media ($tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.review__item-stars {
  margin: 0 0 7px;
  display: flex;
  align-items: center;

  @media ($tablet) {
    margin: 0;
  }

  span {
    font-size: 7px;
    color: #494949;
    display: inline-block;
    margin-left: 5px;

    @media ($tablet) {
      font-size: 14px;
      margin-left: 15px;
    }
  }
}

.review__item-star {
  width: 8px;
  height: 7px;
  fill: #00963f;
  margin-right: 4px;

  @media ($tablet) {
    width: 10px;
    height: 10px;
  }
}

.review__item-author {
  display: flex;
  align-items: center;
}

.review__item-name {
  font-family: $fontBold;
  font-size: 9px;
  color: #494949;
  text-transform: uppercase;

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 600;
    text-transform: none;
  }
}

.review__item-date {
  font-size: 7px;
  color: #494949;
  margin: 0 5px;

  @media ($tablet) {
    font-size: 12px;
    margin-right: 0;
  }
}

.review__item-likes {
  display: flex;

  @media ($tablet) {
    position: absolute;
    right: 15px;
    bottom: 15px;
  }
}

.review__like-svg {
  width: 8px;
  height: 9px;
  fill: #00963f;
  margin-right: 3px;

  @media ($tablet) {
    width: 19px;
    height: 17px;
  }
}

.review__dislike-svg {
  width: 8px;
  height: 9px;
  margin-right: 3px;

  @media ($tablet) {
    width: 19px;
    height: 17px;
  }
}

.review__item-like,
.review__item-dislike {
  font-size: 9px;
  color: #a5a5a5;
  margin-left: 10px;

  @media ($tablet) {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 20px;
  }
}

.review__item-text {
  font-size: 9px;
  color: #494949;
  line-height: 13px;

  @media ($tablet) {
    font-size: 14px;
    line-height: normal;
  }
}

.review__button {
  @media ($tablet) {
    width: 215px;
    margin-top: 15px;
  }
}