@import "../variables";

.breadcrumbs {
  margin: 15px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.breadcrumbs__item {
  margin-right: 7px;
  display: flex;
  align-items: center;

  &::after {
    content: url('../img/svg/icon-arrow.svg');
    display: inline-block;
    width: 10px;
    margin-left: 7px;
    margin-top: 2px;
  }

  &:last-child {
    &::after {
      display: none;
      margin-right: 0;
    }
  }
}

.breadcrumbs__link {
  font-size: 10px;
  color: #06081e;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}