@import "../variables";

.about {
  padding: 15px 35px;
  background-color: #fff;
  margin: 0 -20px;

  @media ($tablet) {
    margin: 0 -15px;
    text-align: center;
    padding-top: 35px;
  }
}

.about__title {
  font-family: $fontBold;
  font-size: 13px;
  color: #494949;
  text-transform: uppercase;

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 25px;
    text-transform: none;
  }
}

.about__text {
  font-size: 9px;
  color: #494949;

  @media ($tablet) {
    font-size: 15px;
    padding: 0 90px;
    margin-top: 20px;
  }
}