@import "../variables";

.cart {
  margin-bottom: 40px;
}

.cart__item {
  display: flex;
  padding-bottom: 15px;
  border-bottom: 1px solid #e4e4e4;
  margin-bottom: 15px;

  @media ($tablet) {
    align-items: center;
  }
}

.cart__img {
  width: 35%;
  margin-right: 10px;

  @media ($tablet) {
    width: 175px;
  }

  img {
    width: 100%;
  }
}

.cart__info-wrapper {
  @media ($tablet) {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
}

.cart__info {
  margin-bottom: 7px;

  @media ($tablet) {
    width: 315px;
    margin-bottom: 0;
  }
}

.cart__title {
  font-size: 9px;
  font-weight: 700;
  color: #494949;
  margin: 0 0 5px;

  @media ($tablet) {
    font-size: 20px;
  }
}

.cart__text {
  font-size: 8px;
  color: #494949;
  margin: 0 0 5px;

  @media ($tablet) {
    font-size: 12px;
  }
}

.cart__count-wrapper {
  margin-bottom: 10px;

  @media ($tablet) {
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.cart__count-label {
  font-size: 9px;
  color: #494949;
  margin: 0 0 5px;

  @media ($tablet) {
    font-size: 12px;
    margin-bottom: 15px;
  }
}

.cart__count {
  display: flex;
  align-items: center;
  margin-left: -4px;
}

.cart__count-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 18px;
  font-size: 16px;
  color: #494949;
  background-color: #d2d2d2;
  margin: 0 4px;
  box-sizing: border-box;
  border: 1px solid #b4b4b4;

  @media ($tablet) {
    width: 33px;
    height: 24px;
  }
}

.cart__count-input {
  border: 1px solid #b4b4b4;
  width: 25px;
  height: 18px;
  font-size: 12px;
  color: #06081e;

  @media ($tablet) {
    width: 33px;
    height: 24px;
    text-align: center;
    font-size: 15px;
  }
}

.cart__price {
  @media ($tablet) {
    margin-left: auto;
  }
}

.cart__price-label {
  display: none;

  @media ($tablet) {
    display: block;
    margin: 0 0 10px;
    font-size: 12px;
    color: #494949;
  }
}

.cart__price-now {
  font-size: 12px;
  font-weight: 700;
  color: #494949;
  letter-spacing: 1.6px;

  @media ($tablet) {
    font-size: 25px;
    letter-spacing: normal;
  }
}

.cart__price-old {
  font-size: 10px;
  font-weight: 600;
  text-decoration: line-through;
  color: #a5a5a5;
  letter-spacing: 1.6px;

  @media ($tablet) {
    font-size: 17px;
    margin-left: 15px;
  }
}

.cart__remove {
  margin-left: auto;

  @media ($tablet) {
    margin-left: 40px;
    margin-top: 10px;
    margin-right: 15px;
  }
}

.cart__btn-svg {
  width: 10px;
  height: 10px;

  @media ($tablet) {
    width: 16px;
    height: 16px;
  }
}

.cart__total {
  padding-left: calc(35% + 10px);
  margin-bottom: 40px;

  @media ($tablet) {
    padding-left: 706px;
  }

  @media ($desktop) {
    padding-left: 886px;
  }
}

.cart__total-title {
  font-family: $fontRegular;
  font-size: 9px;
  color: #494949;
  margin: 0 0 6px;

  @media ($tablet) {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.cart__total-sum {
  font-size: 16px;
  font-weight: 700;
  color: #00963f;
  letter-spacing: 1.6px;
  margin: 0 0 6px;

  @media ($tablet) {
    font-size: 25px;
    letter-spacing: normal;
    margin-bottom: 10px;
  }
}

.cart__total-text {
  font-family: $fontRegular;
  font-size: 8px;
  color: #919191;
  margin: 0;

  @media ($tablet) {
    font-size: 12px;
    width: 110px;
  }
}

.cart__notice {
  margin-bottom: 20px;
}

.cart__notice-title {
  font-family: $fontBold;
  font-size: 11px;
  color: #00963f;

  @media ($tablet) {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0 0 10px;
  }
}

.cart__notice-text {
  font-size: 10px;
  margin: 0;
  color: #494949;

  @media ($tablet) {
    font-size: 12px;
  }
}

.cart__form-item {
  margin-bottom: 20px;

  &--fio,
  &--payment,
  &--delivery,
  &--address,
  &--time {
    @media ($tablet) {
      width: 480px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cart__form-row {
        width: 49%;
      }

      .cart__form-flex {
        width: 100%;
      }
    }

    @media ($desktop) {
      width: 610px;
    }
  }

  &--choose {
    @media ($tablet) {
      width: 790px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cart__form-row {
        width: 32.5%;
      }
    }
  }

  &--comment {
    @media ($tablet) {
      width: 790px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .cart__form-row {
        width: 100%;
      }
    }
  }
}

.cart__form-title {
  font-family: $fontRegular;
  font-size: 13px;
  color: #494949;
  margin: 0 0 15px;

  @media ($tablet) {
    font-family: $fontBold;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    width: 100%;
  }
}

.cart__form-row {
  margin-bottom: 15px;

  .input--default {
    width: 100%;
    height: 35px;

    @media ($tablet) {
      height: 40px;
    }
  }

  .textarea--default {
    width: 100%;

    @media ($tablet) {
      height: 140px;
    }
  }

  .input--radio:checked + .cart__radio-label,
  .input--radio:checked + .cart__delivery-label {
    border-color: #64be8a;
    box-shadow: 0 0 2px 0 #64be8a;
  }
}

.cart__form-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .cart__form-row {
    width: 49%;
  }
}

.cart__radio-label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 35px;
  border: 1px solid #cacaca;
  font-family: $fontRegular;
  font-size: 11px;
  color: #494949;

  @media ($tablet) {
    height: 40px;
  }
}

.cart__form-subtitle {
  font-family: $fontRegular;
  font-size: 11px;
  color: #494949;
  margin: 0 0 15px;

  @media ($tablet) {
    font-family: $fontBold;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 1.4px;
    width: 100%;
  }
}

.cart__delivery-label {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 10px;
  width: 100%;
  height: 35px;
  border: 1px solid #cacaca;
  font-family: $fontRegular;
  font-size: 11px;
  color: #494949;

  @media ($tablet) {
    height: 40px;
    font-size: 12px;
  }
}

.cart__delivery-text {
  font-size: 9px;

  @media ($tablet) {
    font-size: 11px;
  }
}

.cart__form-button {
  @media ($tablet) {
    width: 230px;

    .btn {
      width: 100%;
      height: 40px;
    }
  }
}