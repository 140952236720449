@import "../variables";

.popular-item {
  border-width: 0.833px;
  border-color: #d1d1d2;
  border-style: solid;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);
  padding: 12px 15px 15px;
  position: relative;

  @media ($tablet) {
    width: 305px;
    box-sizing: border-box;
    border-color: transparent;
    box-shadow: none;
    transition: box-shadow .2s;

    &:hover {
      border-color: #d1d1d2;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);
    }
  }

  @media ($desktop) {
    width: 360px;
  }
}

.popular-item__img {
  display: block;
  width: 100%;
}

.popular-item__title {
  margin: 0 0 5px;
  color: #494949;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.6px;

  @media ($tablet) {
    font-size: 15px;
  }

  @media ($desktop) {
    font-size: 18px;
  }
}

.popular-item__category {
  margin: 0 0 5px;
  font-size: 12px;
  color: #494949;

  @media ($tablet) {
    font-size: 13px;
  }

  @media ($desktop) {
    font-size: 15px;
  }
}

.popular-item__price {
  margin: 0;
}

.popular-item__now {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.6px;
  color: #494949;

  @media ($tablet) {
    font-size: 25px;
  }

  @media ($desktop) {
    font-size: 30px;
  }
}

.popular-item__old {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  color: #a5a5a5;
  text-decoration: line-through;
  letter-spacing: 1.6px;
  margin-left: 10px;

  @media ($tablet) {
    font-size: 18px;
  }

  @media ($desktop) {
    font-size: 22px;
  }
}

.popular-item__available {
  position: absolute;
  top: 20px;
  left: 15px;
  display: flex;
  align-items: center;
  font-family: $fontRegular;
  font-size: 9px;
  color: #494949;
  margin: 0;

  @media ($tablet) {
    font-size: 10px;
  }

  @media ($desktop) {
    font-size: 12px;
  }
}

.popular-item__available-svg {
  width: 13px;
  height: 11px;
  margin-right: 9px;
  fill: #00963f;
}

.popular-item__tip {
  position: absolute;
  top: 20px;
  right: 15px;
  margin: 0;
  padding: 4px 7px 5px;
  font-size: 10px;
  font-weight: 600;

  @media ($tablet) {
    font-size: 11px;
    top: 15px;
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
  }

  &--sale {
    color: #fff;
    background-color: #ff6973;

    &::before {
      border-right-color: #ff6973;
    }
  }
}

.popular-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}