@import "../variables";

.city {
  display: none;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 0 20px;

  &--open {
    display: block;
  }

  @media ($tablet) {
    background-color: #fff;
    position: absolute;
    right: auto;
    bottom: auto;
    left: 5px;
    padding: 10px 20px;
    min-width: 150px;
    top: 30px;

    .step-back {
      display: none;
    }
  }
}

.city__form {
  @media ($tablet) {
    display: none;
  }
}

.city__label {
  display: block;
  margin: 0 0 5px;
  font-family: $fontRegular;
  font-size: 8px;
  color: #494949;
}

.city__input {
  height: 35px;
  width: 100%;
  padding: 0 10px;
  border: 1px solid #bcbcbc;
  font-family: $fontRegular;
  font-size: 11px;
  color: #494949;
}

.city__list {
  margin: 25px 0 0 10px;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin: 0;
  }
}

.city__item {
  margin-bottom: 10px;
}

.city__link {
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  text-decoration: none;
  transition: color .2s;

  &--active {
    font-family: $fontBold;
    color: #00963f;
  }

  &:hover {
    color: #00963f;
  }

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 12px;
    font-weight: 700;
  }
}

.city__link-svg {
  width: 11px;
  height: 7px;
  fill: #00963f;
}