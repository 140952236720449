@import "../variables";

.history {
  padding-bottom: 70px;

  .title {
    @media ($tablet) {
      text-align: center;
      font-size: 17px;
    }
  }
}

.history__header {
  padding: 12px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #d2d2d2;
  margin-bottom: 15px;

  @media ($tablet) {
    display: none;
  }
}

.history__header-item {
  margin: 0;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;

  &--id {
    width: 25%;
  }

  &--date {
    width: 25%;
  }

  &--count {
    width: 31%;
  }

  &--total {
    width: 19%;
  }
}

.history__list {
  @media ($tablet) {
    display: none;
  }
}

.history__item {
  background-color: #d2d2d2;
  margin-bottom: 5px;
  padding: 7px 10px;
}

.history__info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.history__item-value {
  margin: 0;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;

  &--id {
    width: 25%;
  }

  &--date {
    width: 25%;
  }

  &--count {
    width: 31%;
  }

  &--total {
    width: 19%;
  }
}

.history__toggle-btn {
  width: 23px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #6d6d6d;
  border-radius: 3px;
  margin-left: auto;
  margin-right: 5px;

  &--open {
    & + .history__content {
      display: block;
    }

    .history__toggle-svg {
      transform: rotate(180deg);
    }
  }
}

.history__toggle-svg {
  width: 15px;
  height: 10px;
}

.history__content {
  padding-top: 5px;
  display: none;
}

.history__content-title {
  margin: 0 0 7px;
  font-family: $fontBold;
  font-size: 9px;
  color: #494949;
}

.history__content-desc {
  margin: 0 0 5px;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
}

.history__buttons {
  margin-top: 10px;
  display: flex;

  .btn {
    height: 25px;
  }

  .btn--secondary {
    width: 90px;
    margin-right: auto;
  }

  .btn--approve {
    width: 60px;
    margin-left: 5px;
  }
}

.history__table {
  display: none;

  @media ($tablet) {
    display: table;
    width: 100%;

    th {
      font-size: 12px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: left;
      border-bottom: 1px solid #c7c7c7;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }

    tr {
      height: 60px;
    }

    td {
      border-bottom: 1px solid #c7c7c7;
      font-size: 12px;
      color: #494949;

      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }
}

.history__status {
  margin: 0;
  font-size: 10px;
  font-weight: 600;

  &--success {
    color: #00963f;
  }
}

.history__link {
  text-transform: uppercase;
  margin: 0 10px;

  &:first-child {
    margin-left: 0;
  }
}