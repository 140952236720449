@import "../variables";

.promo-item {
  @media ($tablet) {
    width: 315px;
  }

  @media ($desktop) {
    width: 360px;
  }
}

.promo-item__img {
  width: 100%;
  display: block;
}

.promo-item__content {
  text-align: center;
  padding: 15px 15px 25px;
  border-width: 0.833px;
  border-color: #d1d1d2;
  border-style: solid;
  border-top: none;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);

  @media ($tablet) {
    box-shadow: none;
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.promo-item__label {
  font-size: 12px;
  text-transform: uppercase;
  color: #494949;
  margin: 0 0 15px;

  @media ($tablet) {
    font-size: 13px;
  }

  @media ($desktop) {
    font-size: 15px;
  }
}

.promo-item__title {
  font-family: $fontBold;
  font-size: 32px;
  text-transform: uppercase;
  color: #494949;
  margin: 0 0 15px;

  @media ($tablet) {
    font-size: 35px;
    line-height: 31px;
    min-height: 62px;
  }

  @media ($desktop) {
    font-size: 40px;
    line-height: 36px;
    min-height: 72px;
  }
}

.promo-item__text {
  font-size: 10px;
  color: #494949;
  padding: 0 20px;
  margin: 0 0 40px;

  @media ($tablet) {
    margin-bottom: 25px;
  }

  @media ($desktop) {
    font-size: 12px;
  }
}
