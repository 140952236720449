@import "../variables";

.order {
  text-align: center;
  padding-bottom: 30px;

  @media ($tablet) {
    padding-bottom: 70px;
  }

  .title {
    @media ($tablet) {
      display: none;
    }
  }
}

.order__list {
  @media ($tablet) {
    width: 575px;
    margin: 60px auto;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: none;
    width: 16px;
    height: 16px;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }
}

.order__arrow {
  width: 16px;
  height: 16px;
  fill: #00963f;

  &--right {
    transform: rotate(180deg);
  }
}

.order__text {
  font-size: 9px;
  color: #494949;
  margin: 0 auto 15px;
  width: 170px;

  @media ($tablet) {
    display: none;
  }
}

.order__sum {
  margin: 20px 0 25px;
  font-size: 10px;
  color: #494949;

  @media ($tablet) {
    font-size: 18px;
  }

  b {
    font-size: 12px;

    @media ($tablet) {
      font-size: 23px;
    }
  }
}

.order__button {
  .btn {
    width: 140px;
    margin: 0 auto;

    @media ($tablet) {
      width: 180px;
      height: 45px;
    }
  }
}

.order__success {
  display: none;

  @media ($tablet) {
    display: block;
  }
}

.order__success-title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $fontBold;
  font-size: 17px;
  color: #494949;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  margin: 0 0 15px;
}

.order__success-svg {
  width: 22px;
  height: 22px;
  fill: #079944;
  margin-right: 15px;
}

.order__success-text {
  margin: 0 0 25px;
  font-size: 14px;
  color: #494949;
}