@import "../variables";

.input {
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;

  &--default {
    height: 35px;
    padding: 0 10px;
    border: 1px solid #bcbcbc;
    font-family: $fontRegular;
    font-size: 11px;
    color: #494949;

    @media ($tablet) {
      height: 45px;
    }
  }

  &--check {
    display: none;

    &:checked + .label::before {
      background: #fff url('../img/svg/icon-check.svg') 50% 50% no-repeat;
      background-size: 85%;
    }
  }
}