@import "variables";

.body {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 12px;
  background-color: #fff;
  min-width: 320px;
  color: #000;

  @media ($tablet) {
    font-size: 14px;
  }

  @media ($desktop) {
    font-size: 16px;
  }

  &--overflow {
    overflow: hidden;
  }
}

.container {
  min-width: 320px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;

  @media ($tablet) {
    width: 990px;
    padding: 0 15px;
  }

  @media ($desktop) {
    width: 1170px;
  }
}

a {
  color: #000;

  &:hover {
    text-decoration: none;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}