@import "../variables";

.promo {
  padding: 15px 0;

  @media ($tablet) {
    padding: 25px 0;
  }

  @media ($desktop) {
    padding: 30px 10px;
  }
}

.promo__list {
  .promo-item {
    &:not(:first-child) {
      margin-top: 15px;

      @media ($tablet) {
        margin-top: 0;
      }
    }
  }

  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}