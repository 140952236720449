@import "../variables";

.main-slider {
  margin: 0 -20px;

  .swiper-scrollbar {
    display: none;

    @media ($tablet) {
      display: block;
      width: 100%;
      left: 0;
      border-radius: 0;
      background: #d2d2d2;
      bottom: 2px;
    }
  }

  .swiper-scrollbar-drag {
    border-radius: 0;
    background: #a5a5a5;
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background-color: #fff;
  }

  .swiper-pagination-bullet-active {
    background: #00963f;
  }

  .swiper-pagination {
    bottom: 20px;

    @media ($tablet) {
      display: none;
    }
  }

  @media ($tablet) {
    margin: 0;
  }
}

.main-slider__item {
  img {
    width: 100%;
  }
}