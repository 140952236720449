@import "../variables";

.catalog {
  @media ($tablet) {
    flex-grow: 1;
  }
}

.catalog__list {

  .catalog-item {
    &:not(:first-child) {
      margin-top: 15px;

      @media ($tablet) {
        margin-top: 0;
      }
    }

    @media ($tablet) {
      margin-right: 5px;
      margin-bottom: 20px;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    @media ($desktop) {
      margin-right: 25px;
    }
  }

  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.catalog__pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.catalog__view {
  display: none;

  @media ($tablet) {
    display: flex;
    align-items: center;
  }
}

.catalog__view-title {
  color: #494949;

  @media ($tablet) {
    margin: 0 10px 0 0;
  }
}

.catalog__view-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.catalog__view-link {
  font-size: 12px;
  color: #494949;
  text-decoration: none;
  display: inline-block;
  padding: 7px;

  &--active {
    background-color: #d2d2d2;
  }

  &:hover {
    color: #00963f;
  }
}

.catalog__pagination {
  margin-top: 30px;

  @media ($tablet) {
    margin-top: 0;
    margin-left: 115px;
  }

  @media ($desktop) {
    margin-left: 165px;
  }
}