@import "../variables";

.header {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);
  padding: 15px 0 10px;

  @media ($tablet) {
    box-shadow: none;
    padding: 10px 0 8px;
  }

  &--b2b {
    @media ($tablet) {
      border-bottom: 1px solid #a5a5a5;
    }
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  @media ($tablet) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.header__menu {
  @media ($tablet) {
    display: none;
  }
}

.header__menu-svg {
  width: 17px;
  height: 12px;
}

.header__logo {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);

  @media ($tablet) {
    position: static;
    transform: none;
    padding-left: 10px;
  }
}

.header__logo-link {
  display: inline-block;
  width: 97px;

  @media ($tablet) {
    width: 221px;
  }

  img {
    width: 100%;
  }
}

.header__user {
  display: flex;
  align-items: center;

  @media ($tablet) {
    flex-direction: row-reverse;
  }
}

.header__auth {
  display: none;

  @media ($tablet) {
    display: block;
    margin-left: 20px;
  }
}

.header__auth-svg {
  width: 25px;
  height: 25px;
}

.header__cart {
  position: relative;
  text-decoration: none;

  @media ($tablet) {
    margin-left: 25px;
  }
}

.header__cart-svg {
  width: 15px;
  height: 14px;

  @media ($tablet) {
    width: 26px;
    height: 24px;
  }
}

.header__exit {
  text-decoration: none;

  @media ($tablet) {
    display: none;
  }
}

.header__exit-svg {
  width: 17px;
  height: 17px;
}

.header__cart-count {
  display: inline-block;
  color: #fff;
  border-radius: 2px;
  background-color: #00963f;
  font-family: $fontBold;
  font-size: 7px;
  padding: 2px;
  position: absolute;
  bottom: 0;
  right: -5px;

  @media ($tablet) {
    font-size: 12px;
    border-radius: 3px;
    bottom: -5px;
    right: -7px;
  }
}

.header__search {
  margin-left: 15px;
  text-decoration: none;

  @media ($tablet) {
    position: absolute;
    top: 12px;
    left: 15px;
    margin: 0;
  }
}

.header__search-svg {
  width: 14px;
  height: 14px;

  @media ($tablet) {
    width: 16px;
    height: 16px;
  }
}

.header__content {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 15px 20px;

  &--open {
    display: block;
  }

  @media ($tablet) {
    display: flex;
    align-items: center;
    position: static;
    background-color: #fff;
    width: 100%;
    padding: 1px 0;
    margin-bottom: 10px;
  }

  &--b2b {
    @media ($tablet) {
      width: auto;
      order: 2;
      padding-top: 5px;
      margin-bottom: 0;

      .language {
        order: 3;
        margin-left: 25px;
        width: 120px;
      }

      .header__content-contacts {
        display: none;
      }
    }

    @media ($desktop) {
      .language {
        margin-left: 40px;
      }
    }
  }
}

.header__content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ($tablet) {
    display: none;
  }
}

.header__content-logo {
  display: inline-block;
  width: 102px;

  img {
    width: 100%;
  }
}

.header__content-close {
  width: 15px;
  height: 15px;
}

.header__content-list {
  margin: 30px 0 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin: 0;
  }
}

.header__content-item {
  padding: 0 10px;

  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;

    @media ($tablet) {
      border: none;
    }
  }

  @media ($tablet) {
    display: none;
    padding: 0;

    &--city {
      display: block;
      width: 266px;
    }

    &--auth {
      display: block;

      .header__content-link {
        display: none;
      }
    }
  }
}

.header__content-link {
  display: flex;
  align-items: center;
  height: 35px;
  text-decoration: none;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  text-transform: uppercase;

  @media ($tablet) {
    height: auto;
    font-family: Open Sans, Arial, sans-serif;
    font-size: 12px;
    text-transform: none;
  }
}

.header__content-svg {
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
}

.header__content-drop {
  display: none;

  @media ($tablet) {
    display: block;
    width: 16px;
    height: 12px;
  }

  &--open {
    transform: rotate(180deg);
  }
}

.header__content-pin {
  width: 15px;
  height: 19px;
  fill: #06081e;

  @media ($tablet) {
    display: none;
  }
}

.header__content-folder {
  width: 18px;
  height: 16px;
  fill: #06081e;
}

.header__content-arrow {
  width: 10px;
  height: 15px;
  margin-left: auto;
}

.header__content-contacts {
  @media ($tablet) {
    margin-left: auto;
  }
}

.header__content-phone {
  font-family: $fontBold;
  font-size: 12px;
  color: #00963f;
  text-decoration: none;

  @media ($tablet) {
    display: flex;
    align-items: center;
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: 700;
    color: #06081e;
  }
}

.header__content-text {
  font-family: $fontRegular;
  font-size: 8px;
  color: #494949;
  text-transform: uppercase;

  @media ($tablet) {
    display: none;
  }
}

.header__content-phone-svg {
  display: none;

  @media ($tablet) {
    display: inline-block;
    width: 11px;
    height: 11px;
    fill: #0a9a47;
    margin-right: 8px;
  }
}

.header__catalog {
  display: none;

  @media ($tablet) {
    display: block;
    margin-left: 35px;

    .btn {
      width: 120px;
      height: 42px;
    }
  }
}

.header__catalog-svg {
  width: 15px;
  height: 13px;
  fill: #fff;
  margin-right: 15px;
}

.header__search-wrapper {
  @media ($tablet) {
    position: relative;
    margin-left: 5px;
  }
}

.header__search-form {
  display: none;
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 10;
  width: calc(100% + 40px);
  background-color: #fff;
  margin: 0 -20px;

  &--open {
    display: flex;
  }

  @media ($tablet) {
    display: flex;
    position: static;
    width: auto;
    margin: 0;
    background-color: transparent;
  }

  .btn {
    width: 87px;
    height: 42px;
  }
}

.header__search-input {
  width: 377px;
  height: 42px;
  border: 1px solid #494949;
  padding: 0 20px 0 45px;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 12px;

  @media ($desktop) {
    width: 540px;
  }
}

.header__catalog-drop {
  display: none;

  @media ($tablet) {
    position: absolute;
    left: 0;
    top: 105px;
    right: 0;
    z-index: 10;
    background-color: #f3f3f3;

    &--open {
      display: flex;
    }
  }
}

.header__catalog-item {
  width: 25%;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  padding: 15px 0 15px 25px;

  @media ($desktop) {
    padding: 20px 35px;
  }
}

.header__catalog-title {
  font-family: $fontBold;
  font-size: 16px;
  color: #00963f;
  text-transform: uppercase;
  margin: 0 0 17px;

  @media ($desktop) {
    font-size: 20px;
    margin-bottom: 20px;
  }
}

.header__catalog-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.header__catalog-list-item {
  margin-bottom: 10px;

  @media ($desktop) {
    margin-bottom: 5px;
  }
}

.header__catalog-link {
  font-size: 12px;
  color: #06081e;
  text-decoration: none;
  transition: color .2s;

  &--active {
    color: #00963f;
  }

  &:hover {
    color: #00963f;
  }
}

.header__manager {
  margin-bottom: 40px;

  @media ($tablet) {
    display: flex;
    margin-left: 20px;
    margin-bottom: 0;
  }

  @media ($desktop) {
    margin-left: 40px;
  }
}

.header__manager-title {
  font-family: $fontRegular;
  font-size: 12px;
  text-transform: uppercase;
  color: #494949;
  margin: 20px 0 15px;

  @media ($tablet) {
    font-size: 10px;
    margin: 0 0 5px;
    text-transform: none;
  }
}

.header__manager-name {
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  margin: 0 0 10px;

  @media ($tablet) {
    margin: 0;
    font-family: $fontBold;
    font-size: 14px;
  }
}

.header__manager-info {
  @media ($tablet) {
    column-count: 2;
    margin-left: 30px;
  }

  @media ($desktop) {
    margin-left: 70px;
  }
}

.header__manager-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media ($tablet) {
    &:hover .header__manager-svg {
      fill: #00963e;
    }
    
    &:hover .header__manager-content {
      color: #00963e;
    }
  }
}

.header__manager-svg {
  fill: #00963e;
  margin-right: 6px;

  @media ($tablet) {
    fill: #a5a5a5;
  }

  &--phone {
    width: 9px;
    height: 9px;
  }

  &--email {
    width: 12px;
    height: 10px;
    margin-right: 3px;
  }

  &--skype {
    width: 12px;
    height: 11px;
    margin-right: 3px;
  }
}

.header__manager-content {
  font-size: 9px;
  margin: 0;
  text-decoration: none;
}

.header__profile {
  margin-bottom: 40px;

  @media ($tablet) {
    margin-bottom: 0;
    border: 2px solid #d2d2d2;
    margin-left: 20px;
    padding: 3px 10px;
  }

  @media ($desktop) {
    margin-left: 40px;
  }
}

.header__profile-name {
  font-family: $fontBold;
  font-size: 10px;
  color: #494949;
  margin: 0 0 10px;

  @media ($tablet) {
    font-size: 14px;
    margin-bottom: 0;
  }
}

.header__profile-list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
  }
}

.header__profile-item {
  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;

    @media ($tablet) {
      border-top: none;
    }
  }
}

.header__profile-link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 35px;
  text-decoration: none;
  font-size: 10px;
  font-family: $fontRegular;

  @media ($tablet) {
    display: inline-block;
    width: auto;
    height: auto;
    font-size: 11px;
    margin-right: 15px;

    .header__content-arrow {
      display: none;
    }
  }
}