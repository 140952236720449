@import "../variables";

.title {
  font-weight: 400;
  margin: 0;
  padding: 0;

  &--main {
    font-family: $fontBold;
    font-size: 13px;
    text-transform: uppercase;
    color: #494949;
    margin-bottom: 15px;

    @media ($tablet) {
      font-size: 25px;
      margin-bottom: 30px;
    }

    @media ($desktop) {
      font-size: 30px;
      margin-bottom: 40px;
    }
  }
}