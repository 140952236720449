@import "../variables";

.nav {
  margin: 80px 0 20px;

  @media ($tablet) {
    margin: 0 0 0 20px;
    order: 3;
  }

  @media ($desktop) {

  }
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    display: flex;
    align-items: center;
  }
}

.nav__item {
  padding: 0 10px;

  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;

    @media ($tablet) {
      border: none;
    }
  }

  @media ($tablet) {
    padding: 0;
    margin-right: 20px;
  }
}

.nav__link {
  display: flex;
  align-items: center;
  height: 25px;
  text-decoration: none;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  text-transform: uppercase;

  @media ($tablet) {
    height: auto;
    font-family: Open Sans, Arial, sans-serif;
    font-size: 12px;
    text-transform: none;
    transition: color .2s;

    &:hover {
      color: #00963f;
    }
  }
}