@import "../variables";

.review-add {
  display: none;
  background-color: #fff;
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  position: relative;

  @media ($tablet) {
    width: 600px;
  }

  .title {
    font-family: $fontBold;
    margin-bottom: 3px;
  }
}

.review-add__close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.review-add__close-svg {
  width: 14px;
  height: 14px;
}

.review-add__stars-wrapper {
  margin-bottom: 20px;
}

.review-add__name {
  font-size: 11px;
  color: #494949;
  margin: 0 0 15px;
}

.review-add__stars-title {
  font-family: $fontBold;
  font-size: 11px;
  color: #494949;
  margin: 0 0 5px;
}

.review-add__star {
  width: 14px;
  height: 13px;
  fill: #00963f;
  margin-right: 2px;
}

.review-add__form {
  .textarea,
  .input {
    width: 100%;
    height: 35px;

    @media ($tablet) {
      height: 45px;
    }
  }
}

.review-add__item {
  margin-bottom: 25px;

  &--button {
    margin-bottom: 0;

    .btn {
      margin: 0 auto;
    }
  }
}