@import "../variables";

.order-item {
  width: 185px;
  border: 1px solid #dbdbdb;
  padding-bottom: 20px;
  position: relative;
  margin: 0 auto;

  @media ($tablet) {
    width: 150px;
  }
}

.order-item__img {
  img {
    width: 90%;
    display: block;
    margin: 0 auto;
  }
}

.order-item__title {
  font-family: $fontBold;
  font-size: 16px;
  color: #494949;
  margin: 0 0 5px;
  text-transform: uppercase;
}

.order-item__text {
  font-size: 10px;
  color: #494949;
  margin: 0 0 10px;
}

.order-item__sum {
  font-size: 10px;
  color: #494949;
  margin: 0;
}

.order-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}