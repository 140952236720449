@import "../variables";

.catalog-lite {
  display: none;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 0 20px;

  &--open {
    display: block;
  }
}

.catalog-lite__title {
  display: flex;
  align-items: center;
  width: 100%;
  text-decoration: none;
  font-family: $fontBold;
  font-size: 8px;
  color: #00963f;
  height: 32px;
  border-bottom: 1px solid #dbdbdb;
  padding: 0 10px;
}

.catalog-lite__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.catalog-lite__list-item {
  padding: 0 10px;

  &:not(:first-child) {
    border-top: 1px solid #dbdbdb;
  }
}

.catalog-lite__link {
  display: flex;
  align-items: center;
  height: 32px;
  text-decoration: none;
  font-family: $fontRegular;
  font-size: 11px;
  color: #494949;

  &--drop {
    & + .catalog-lite__drop {
      display: block;
    }

    .catalog-lite__svg {
      transform: rotate(180deg);
    }
  }
}

.catalog-lite__svg {
  width: 18px;
  height: 12px;
  margin-left: auto;
}

.catalog-lite__drop {
  margin: 5px 0 15px 10px;
  padding: 0;
  list-style-type: none;
  display: none;
}

.catalog-lite__drop-link {
  display: flex;
  align-items: center;
  height: 25px;
  text-decoration: none;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
}