@import "../variables";

.social-reg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-reg__text {
  margin: 0 20px 0 0;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  width: 120px;

  @media ($tablet) {
    width: auto;
    margin-right: 10px;
  }
}

.social-reg__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.social-reg__item {
  margin: 0 6px;
}

.social-reg__link {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

  &--fb {
    background-color: #395599;
  }

  &--vk {
    background-color: #5181b8;
  }
}

.social-reg__fb {
  fill: #fff;
  width: 12px;
  height: 18px;
}

.social-reg__vk {
  width: 20px;
  height: 14px;
  fill: #fff;
}