@import "../variables";

.restore {
  display: none;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 0 20px;

  &--open {
    display: block;
  }

  @media ($tablet) {
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: auto;
    left: auto;
    padding: 10px 20px 15px;
    width: 260px;
    top: 96px;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;

    .step-back {
      display: none;
    }
  }
}

.restore__form-item {
  margin-bottom: 15px;

  .input {
    width: 100%;
  }

  .btn {
    width: 100%;

    @media ($tablet) {
      height: 45px;
    }
  }
}