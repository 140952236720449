@import "../variables";

.catalog-item {
  border-width: 0.833px;
  border-color: #d1d1d2;
  border-style: solid;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);
  padding: 12px 15px 15px;
  position: relative;

  @media ($tablet) {
    width: 240px;
    box-sizing: border-box;
    border-color: transparent;
    box-shadow: none;
    transition: box-shadow .2s;

    &:hover {
      border-color: #d1d1d2;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);
    }
  }

  @media ($desktop) {
    width: 265px;
  }
}

.catalog-item__img-wrapper {
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.catalog-item__img {
  display: block;
  width: 100%;
}

.catalog-item__title {
  margin: 0 0 5px;
  color: #494949;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1.6px;

  @media ($tablet) {
    font-size: 12px;
  }

  @media ($desktop) {
    font-size: 14px;
  }
}

.catalog-item__category {
  margin: 0 0 5px;
  font-size: 12px;
  color: #494949;

  @media ($tablet) {
    font-size: 10px;
  }

  @media ($desktop) {
    font-size: 12px;
  }
}

.catalog-item__price {
  margin: 0;
}

.catalog-item__now {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1.6px;
  color: #494949;

  @media ($tablet) {
    font-size: 20px;
  }

  @media ($desktop) {
    font-size: 22px;
  }
}

.catalog-item__old {
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  color: #a5a5a5;
  text-decoration: line-through;
  letter-spacing: 1.6px;
  margin-left: 10px;

  @media ($tablet) {
    font-size: 14px;
    margin-left: 5px;
  }

  @media ($desktop) {
    font-size: 16px;
  }
}

.catalog-item__available,
.catalog-item__unavailable {
  position: absolute;
  top: 20px;
  left: 15px;
  display: flex;
  align-items: center;
  font-family: $fontRegular;
  font-size: 9px;
  color: #494949;
  margin: 0;

  @media ($tablet) {
    font-size: 10px;
  }

  @media ($desktop) {
    font-size: 12px;
  }
}

.catalog-item__available-svg {
  width: 13px;
  height: 11px;
  margin-right: 9px;
  fill: #00963f;
}

.catalog-item__unavailable-svg {
  width: 8px;
  height: 8px;
  fill: #ef767b;
  margin-right: 9px;
  margin-top: -1px;
}

.catalog-item__tip-wrapper {
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media ($tablet) {
    top: 15px;
  }
}

.catalog-item__tip {
  margin: 0 0 3px;
  padding: 4px 7px 5px;
  font-size: 10px;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;

  @media ($tablet) {
    font-size: 8px;
  }

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    display: block;
    position: absolute;
    top: 0;
    left: -10px;

    @media ($tablet) {
      border-top: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid transparent;
      left: -9px;
    }
  }

  &--sale {
    color: #fff;
    background-color: #ff6973;

    &::before {
      border-right-color: #ff6973;
    }
  }

  &--new {
    color: #fff;
    background-color: #009ee3;

    &::before {
      border-right-color: #009ee3;
    }
  }
}

.catalog-item-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 10px;
}

.catalog-item__360 {
  width: 30px;
}

.catalog-item__buttons {
  display: flex;
  justify-content: space-between;

  .btn {
    height: 30px;

    &--approve {
      width: 90px;
    }

    &--default {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 90px;

      @media ($desktop) {
        font-size: 11px;
      }
    }
  }
}