@import "../variables";

.regb2b {
  padding: 30px 0;

  .title {
    @media ($tablet) {
      text-align: center;
      font-size: 17px;
    }
  }
}

.regb2b__form {
  @media ($tablet) {
    width: 280px;
    margin: 0 auto;
  }

  @media ($desktop) {
    width: 370px;
  }
}

.regb2b__item {
  margin-bottom: 15px;

  .input {
    width: 100%;
  }

  .textarea {
    width: 100%;
    height: 120px;
  }

  &--check {
    margin-right: 20px;
  }

  &--button {
    text-align: center;
    margin-top: 30px;

    .btn {
      width: 155px;
      margin: 0 auto;
    }
  }
}

.regb2b__text {
  margin: 20px 0;
  width: 150px;
  font-size: 10px;
  color: #494949;
}

.regb2b__item-wrapper {
  display: flex;

  &--auth {
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;

    @media ($tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    .regb2b__item {
      margin-bottom: 0;
    }

    .btn {
      width: 130px;

      @media ($tablet) {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.regb2b__link {
  display: inline-block;
  font-size: 9px;
  text-decoration: none;
  color: #494949;
  margin-top: 20px;
}

.regb2b__item-links {
  display: flex;
  justify-content: space-around;

  @media ($tablet) {
    justify-content: space-between;
  }
}

.regb2b__item-text {
  font-size: 11px;
  color: #494949;
}

.regb2b__restore {
  font-size: 11px;
  color: #494949;
  margin-bottom: 20px;

  @media ($tablet) {
    text-align: center;
  }
}