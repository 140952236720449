@import "../variables";

.pagination-page {
  margin: 30px 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-page__link {
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  text-decoration: none;

  &--active {
    font-weight: 700;
  }
}