@import "../variables";

.step-back {
  margin-bottom: 15px;
}

.step-back__link {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.header__content-back {
  width: 20px;
  height: 27px;
  margin-right: 10px;
}

.step-back__title {
  margin: 0;
  font-family: $fontBold;
  font-size: 12px;
  text-transform: uppercase;
  color: #494949;
}

.step-back__text {
  margin: 5px 0 0;
  font-family: $fontRegular;
  font-size: 8px;
  color: #494949;
}