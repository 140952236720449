@import "../variables";

.pagination {

}

.pagination__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination__link {
  font-size: 12px;
  color: #494949;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;

  &--active {
    background-color: #d2d2d2;
  }

  &:hover {
    color: #00963f;
  }

  &--prev {
    .pagination__svg {
      transform: rotate(180deg);
    }
  }
}

.pagination__svg {
  width: 15px;
  height: 12px;
  fill: #8e8f8e;

  &:hover {
    fill: #00963f;
  }
}