@import "../variables";

.auth {
  display: none;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 0 20px;

  &--open {
    display: block;
  }

  @media ($tablet) {
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: auto;
    left: auto;
    padding: 10px 20px 15px;
    width: 260px;
    top: 96px;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;

    .step-back {
      display: none;
    }
  }
}

.auth__form-item {
  margin-bottom: 15px;

  .input {
    width: 100%;
  }

  &--line {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media ($tablet) {
      flex-direction: column-reverse;
      align-items: flex-start;
    }
  }
  .btn {
    width: 130px;

    @media ($tablet) {
      width: 100%;
      height: 45px;
      margin-bottom: 20px;
    }
  }
}

.auth__form-forget {
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  margin-right: 35px;

  @media ($tablet) {
    font-size: 12px;
    margin: 0;
  }
}

.auth__new {
  margin-top: 25px;
  text-align: center;

  @media ($tablet) {
    display: none;
  }
}

.auth__new-link {
  font-family: $fontRegular;
  font-size: 11px;
  color: #494949;
}