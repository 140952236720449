@import "../variables";

.info {
  padding: 15px 0 30px;
  text-align: center;

  p {
    font-size: 10px;
    color: #494949;

    @media ($tablet) {
      font-size: 14px;
      width: 740px;
      margin: 15px auto;
    }
  }

  img {
    width: 100%;
  }

  .title,
  h1,
  h2 {
    font-weight: 400;
    margin: 0 0 15px;
    padding: 0;
    font-family: $fontBold;
    font-size: 13px;
    text-transform: uppercase;
    color: #494949;

    @media ($tablet) {
      font-size: 17px;
      margin-bottom: 15px;
    }
  }
}

.info__text {
  font-size: 10px;
  color: #494949;
}

.info__img {
  width: 100%;
}

.info__button {
  margin-top: 30px;

  @media ($tablet) {
    margin-top: 50px;
  }

  .btn {
    @media ($tablet) {
      width: 180px;
      margin: 0 auto;
    }
  }
}