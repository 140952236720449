@import "../variables";

.stock {
  padding: 30px 0;
}

.stock__form {
  @media ($tablet) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
}

.stock__form-item {
  position: relative;
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 10px;
  }

  &--category {
    @media ($tablet) {
      width: 260px;
    }
  }

  &--price {
    @media ($tablet) {
      margin: 0 30px;
      width: 200px;

      .stock__label {
        margin-top: 0;
      }
    }
  }

  &--search {
    display: flex;

    @media ($tablet) {
      flex-grow: 1;
      flex-wrap: wrap;
    }

    .stock__label {
      display: none;

      @media ($tablet) {
        display: block;
        width: 100%;
        margin-top: 0;
      }
    }
  }

  &--check {
    @media ($tablet) {
      display: flex;

      .stock__form-row {
        margin-right: 20px;
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

.stock__label {
  font-family: $fontBold;
  font-size: 10px;
  color: #494949;
  display: block;
  margin-bottom: 5px;

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 11px;
    color: #06081e;
    margin-bottom: 10px;
  }
}

.stock__select {
  width: 100%;
  font-family: $fontRegular;
  font-size: 11px;
  color: #fff;
  background-color: #00963f;
  height: 35px;
  padding: 0 30px 0 15px;

  option {
    &:checked {
      background-color: #00963f;
    }

    &:not(:checked) {
      background-color: #494949;
    }
  }
}

.stock__range-wrapper {
  .noUi-target {
    background: #a5a5a5;
    border-radius: 0;
    border: none;
    box-shadow: none;
    height: 4px;
    width: 95%;
    margin: 15px auto;
  }

  .noUi-connect {
    background: #00963f;
  }

  .noUi-handle {
    width: 12px;
    height: 12px;
    background-color: #00963f;
    box-shadow: none;
    border: 1px solid #aad5bc;
    border-radius: 100%;
    box-sizing: border-box;
    top: -4px;
    right: 0;

    &::before,
    &::after {
      display: none;
    }
  }
}

.stock__range-sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 0;
  color: #494949;
}

.stock__range-input {
  width: 40%;
  background: transparent;
  border: 1px solid #aeaeae;
  box-sizing: border-box;
  height: 35px;
  padding: 0 15px;
  font-family: $fontRegular;
  font-size: 12px;
  color: #494949;

  &:focus {
    border-color: #00963f;
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 1, 0.3);
  }
}

.stock__search {
  position: absolute;
  top: 10px;
  left: 15px;

  @media ($tablet) {
    top: 32px;
  }
}

.stock__search-svg {
  width: 14px;
  height: 14px;

  @media ($tablet) {
    width: 16px;
    height: 16px;
  }
}

.stock__search-input {
  height: 35px;
  border: 1px solid #494949;
  padding: 0 20px 0 45px;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-size: 12px;
  flex-grow: 1;
}

.stock__form-row {
  margin-bottom: 10px;

  .label {
    font-size: 11px;
  }
}

.stock__list {

}

.stock__item-header {
  padding: 12px 10px;
  font-family: $fontRegular;
  font-size: 10px;
  background-color: #d2d2d2;
  margin-bottom: 5px;

  span {
    margin-right: 39px;
  }

  @media ($tablet) {
    display: none;
  }
}

.stock__item {
  margin-bottom: 5px;
}

.stock__item-top {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  background-color: #e4e4e4;
  border-bottom: 2px solid #dcdcdc;

  &--open + .stock__item-content {
    display: block;
  }

  @media ($tablet) {
    display: none;
  }
}

.stock__item-photo {
  width: 50px;
  margin-right: 15px;

  img {
    display: block;
    width: 100%;
  }
}

.stock__item-about {
  width: 110px;
}

.stock__item-name {
  margin: 0;
  font-family: $fontRegular;
  font-size: 10px;
}

.stock__item-material {
  margin: 0;
  font-family: $fontRegular;
  font-size: 9px;
}

.stock__item-article {
  margin: 0;
  font-family: $fontBold;
  font-size: 9px;
}

.stock__item-actions {
  margin-left: auto;
}

.stock__item-btn {
  width: 23px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 1px solid #808080;
  margin: 4px 0;
}

.stock__item-toggle {
  width: 15px;
  height: 10px;
}

.stock__item-remove {
  width: 7px;
  height: 7px;
}

.stock__item-content {
  padding: 10px;
  background-color: #e4e4e4;
  display: none;

  @media ($tablet) {
    display: none;
  }
}

.stock__item-row {
  margin-bottom: 15px;

  &--size {
    display: flex;
    align-items: center;

    .stock__item-label {
      margin: 0;
    }
  }

  &--count {
    display: flex;
    align-items: center;
    margin: 20px 0;

    .stock__item-label {
      margin: 0;
    }
  }

  &--button {
    margin-top: 40px;

    .btn {
      width: 200px;
      margin: 0 auto;
    }
  }
}

.stock__item-label {
  font-family: $fontBold;
  font-size: 10px;
  color: #494949;
  display: block;
  margin-bottom: 5px;

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 14px;
    color: #06081e;
  }
}

.stock__item-list {
  margin: 15px 0;
  padding: 0;
  list-style-type: none;

  &--color {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media ($tablet) {
      display: block;
    }

    .label--check::before {
      display: none;

      @media ($tablet) {
        display: block;
      }
    }
  }
}

.stock__item-block {
  margin-bottom: 10px;

  .input--check {
    &:checked + .label--check .stock__item-color {
      border-color: #00963f;
    }
  }
}

.stock__item-color-text {
  display: none;

  @media ($tablet) {
    display: block;
    font-family: $fontRegular;
    font-size: 12px;
    color: #494949;
    margin-left: 13px;
  }
}

.stock__item-color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 25px;
  color: #fff;
  font-family: $fontBold;
  font-size: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &--white {
    background-color: #fff;
    color: #494949;
    border: 1px solid #a9a9a9;
  }

  @media ($tablet) {
    font-size: 0;
    width: 15px;
    height: 15px;
  }
}

.stock__item-size-link {
  font-size: 9px;
  color: #494949;
  display: inline-block;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #494949;
  line-height: 12px;

  @media ($tablet) {
    font-size: 12px;
  }
}

.stock__item-size {
  margin-right: 5px;

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 21px;
    border: 1px solid #b6b6b6;
    border-radius: 2px;
    font-size: 10px;
    text-transform: uppercase;
    color: #06081e;

    &::before {
      display: none;
    }

    @media ($tablet) {
      width: 33px;
      height: 24px;
      font-size: 12px;
      box-sizing: border-box;
    }
  }

  .input:checked + .label {
    background-color: #00963f;
    color: #fff;
    border-color: #66c08c;
  }
}

.stock__item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 8px;
}

.stock__item-count-wrapper {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.stock__item-label {
  font-size: 9px;
  color: #494949;
  margin: 0 0 5px;

  @media ($tablet) {
    font-size: 12px;
    margin-bottom: 15px;
  }
}

.stock__item-count-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 18px;
  font-size: 16px;
  color: #494949;
  background-color: #d2d2d2;
  margin: 0 4px;
  box-sizing: border-box;
  border: 1px solid #b4b4b4;

  @media ($tablet) {
    width: 33px;
    height: 24px;
  }
}

.stock__item-count-input {
  border: 1px solid #b4b4b4;
  width: 25px;
  height: 18px;
  font-size: 12px;
  color: #06081e;

  @media ($tablet) {
    width: 33px;
    height: 24px;
    text-align: center;
    font-size: 15px;
  }
}

.stock__item-cart {
  width: 15px;
  height: 14px;
  fill: #fff;
  margin-left: 7px;
}

.stock__table {
  display: none;

  @media ($tablet) {
    display: table;
    width: 100%;

    th {
      font-size: 12px;
      font-weight: 700;

      &.stock__table-name {
        text-align: left;
      }
    }

    td {
      text-align: center;
      font-size: 12px;
    }
  }

  .stock__item-name,
  .stock__item-material,
  .stock__item-article {
    width: 120px;
    text-align: left;
    font-size: 12px;
  }

  .stock__item-count-wrapper {
    margin-left: 0;
    justify-content: center;
  }

  &--approve {
    th,
    td {
      text-align: left;
    }
  }
}

.stock__table-img {
  width: 50px;
}

.stock__total {
  display: none;

  @media ($tablet) {
    display: flex;
    align-items: center;
  }
}

.stock__total-table {
  font-size: 12px;
  border-spacing: 20px 5px;
}

.stock__total-button {
  margin-left: auto;
}

.stock__title {
  display: flex;
  justify-content: space-between;

  @media ($tablet) {
    justify-content: center;
    font-size: 17px;
  }
}

.stock__title-remove {
  font-family: $fontRegular;

  @media ($tablet) {
    display: none;
  }
}

.stock__all-wrapper {
  @media ($tablet) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 30px 0;
  }
}

.stock__all {
  margin: 30px 0;

  @media ($tablet) {
    display: flex;
    margin: 0;
  }
}

.stock__all-row {
  font-size: 9px;
  display: flex;
  justify-content: space-between;

  @media ($tablet) {
    flex-direction: column;
    font-size: 12px;
    margin: 0 30px 0 0;

    b {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}

.stock__all-text {
  font-size: 9px;
  width: 180px;

  @media ($tablet) {
    font-size: 12px;
    margin: 0;
  }

  span {
    font-weight: 700;
    color: #00963e;
  }
}

.stock__links {
  margin-bottom: 20px;

  @media ($tablet) {
    width: 100%;
    order: 4;
    margin-top: 30px;
    margin-bottom: 0;
    display: flex;
  }

  a {
    font-family: $fontRegular;
    font-size: 11px;
    text-transform: uppercase;
    margin-right: 30px;

    @media ($tablet) {
      font-size: 14px;
      text-decoration: none;
      margin-right: 60px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.stock__all-total {
  font-size: 11px;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  padding: 7px 10px;
  background-color: #e4e4e4;
  margin-bottom: 25px;

  @media ($tablet) {
    background-color: transparent;
    flex-direction: column;
    padding: 0;
    margin: 0 30px 0 0;
    font-size: 12px;

    b:first-child {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
}

.stock__continue {
  text-align: center;

  @media ($tablet) {
    margin-left: auto;
  }

  .btn {
    width: 160px;
    margin: 0 auto 20px;

    @media ($tablet) {
      margin: 0 auto;
      width: 127px;
    }
  }

  &--success {
    @media ($tablet) {
      display: none;
    }
  }
}

.stock__continue-link {
  font-size: 10px;

  @media ($tablet) {
    display: none;
  }
}

.stock__links-remove {
  display: none;

  @media ($tablet) {
    display: inline-block;
    margin-left: auto;
  }
}

.stock__links-back {
  display: none;

  @media ($tablet) {
    display: inline-block;
  }
}

.stock__contacts {
  margin-bottom: 30px;

  @media ($tablet) {
    display: flex;
  }
}

.stock__contacts-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @media ($tablet) {
    width: 25%;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.stock__contacts-title {
  font-size: 9px;

  @media ($tablet) {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.stock__contacts-content {
  margin: 0;
  font-size: 9px;
  max-width: 45%;

  @media ($tablet) {
    font-size: 11px;
    max-width: 100%;
  }

  a {
    display: block;
  }
}

.stock__form-send {
  @media ($tablet) {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: flex-end;
  }

  .textarea {
    width: 100%;
    margin-bottom: 25px;
    height: 40px;

    @media ($tablet) {
      width: 50%;
      margin-bottom: 0;
      height: 120px;
    }
  }
}

.stock__text-success {
  font-size: 10px;
  color: #494949;

  @media ($tablet) {
    text-align: center;
    font-size: 11px;
    margin-bottom: 15px;
  }
  
  b {
    color: #00963e;
  }
}

.stock__number {
  font-size: 11px;
  color: #494949;
  border-bottom: 1px solid #ededed;
  padding-bottom: 10px;

  @media ($tablet) {
    border-bottom: none;
    font-size: 14px;
  }
}

.stock__manager {
  border-bottom: 1px solid #ededed;
  border-top: 1px solid #ededed;
  padding: 10px 0 0;
  margin-bottom: 10px;

  @media ($tablet) {
    border: none;
    margin-bottom: 30px;
    display: flex;
  }
}

.stock__manager-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  @media ($tablet) {
    width: 25%;
    flex-direction: column;
    align-items: flex-start;
  }

  &--name {
    display: block;

    .stock__manager-title {
      display: block;
      font-size: 9px;
      margin-bottom: 5px;

      @media ($tablet) {
        font-size: 12px;
        margin-bottom: 10px;
      }
    }
  }
}

.stock__manager-title {
  display: none;

  @media ($tablet) {
    display: block;
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.stock__manager-content {
  font-size: 9px;
  margin: 0;

  @media ($tablet) {
    font-size: 11px;
  }
}

.stock__manager-svg {
  fill: #00963e;
  margin-right: 6px;

  @media ($tablet) {
    display: none;
  }

  &--phone {
    width: 9px;
    height: 9px;
  }

  &--email {
    width: 12px;
    height: 10px;
    margin-right: 3px;
  }

  &--skype {
    width: 12px;
    height: 11px;
    margin-right: 3px;
  }
}

.stock__item-add {
  fill: #fff;
  width: 23px;
}

.stock__add-button {
  width: 23px;
  padding: 3px;
}