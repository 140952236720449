@import "../variables";

.language {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @media ($tablet) {
    margin-bottom: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.language__title {
  display: none;

  @media ($tablet) {
    display: block;
    width: 100%;
    font-family: $fontRegular;
    font-size: 10px;
    color: #494949;
    margin: 0 0 5px;
  }
}

.language__full {
  @media ($tablet) {
    display: none;
  }
}

.language__short {
  display: none;

  @media ($tablet) {
    display: block;
  }
}

.language__item {
  width: 50%;
  height: 35px;
  font-family: $fontBold;
  font-size: 11px;
  color: #353534;
  text-decoration: none;
  display: flex;
  align-items: center;
  background-color: #d2d2d2;
  justify-content: center;

  @media ($tablet) {
    width: 57px;
    height: 20px;
    font-family: $fontRegular;
    font-size: 12px;
    color: #494949;
    text-transform: uppercase;
    border-radius: 2px;
    background-color: #fff;
  }

  &--active {
    background-color: #00963f;
    color: #fff;

    @media ($tablet) {
      background-color: #d2d2d2;
      color: #494949;
    }
  }
}

.language__item-svg {
  width: 12px;
  height: 12px;
  margin-right: 5px;
}