@import "../variables";

.label {
  display: inline-block;
  padding: 0;
  margin: 0;

  &--check {
    font-family: $fontRegular;
    font-size: 9px;
    color: #494949;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      width: 15px;
      height: 15px;
      box-sizing: border-box;
      border: 1px solid #bcbcbc;
      margin-right: 15px;
      display: block;
      background-color: #fff;
      flex-shrink: 0;
    }

    @media ($tablet) {
      font-size: 12px;
    }
  }

  a {
    color: #00963f;
    display: inline-block;
    margin-left: 3px;
  }
}