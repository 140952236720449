@import "../variables";

.select {
  appearance: none;
  border: none;
  padding: 0;
  margin: 0;
}

.select-wrapper {
  position: relative;
}

.select-wrapper__svg {
  fill: #fff;
  width: 24px;
  height: 17px;
  position: absolute;
  top: 9px;
  right: 5px;
  pointer-events: none;
}