@import "../variables";

.divider {
  height: 15px;
  text-align: center;
  position: relative;
  margin: 25px 0;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: rotateY(-50%);
    height: 1px;
    background-color: #51b57b;
  }

  @media ($tablet) {
    margin: 15px 0;
  }
}

.divider__text {
  position: relative;
  z-index: 1;
  padding: 0 20px;
  background-color: #f2f2f2;
  color: #494949;
  font-family: $fontRegular;
  font-size: 8px;

  @media ($tablet) {
    font-size: 10px;
    background-color: #fff;
    top: -3px;
  }
}