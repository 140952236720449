@import "../variables";

.textarea {
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  box-sizing: border-box;

  &--default {
    min-height: 35px;
    padding: 10px;
    border: 1px solid #bcbcbc;
    font-family: $fontRegular;
    font-size: 11px;
    color: #494949;
    resize: vertical;

    @media ($tablet) {
      min-height: 45px;
    }
  }
}