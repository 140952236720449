@import "../variables";

.popular {
  padding: 15px 0;

  @media ($tablet) {
    padding: 25px 0 5px;
  }
}

.popular__list {
  .popular-item {
    &:not(:first-child) {
      margin-top: 15px;

      @media ($tablet) {
        margin-top: 0;
      }
    }

    @media ($tablet) {
      margin-right: 22px;
      margin-bottom: 20px;

      &:nth-child(3n+3) {
        margin-right: 0;
      }
    }

    @media ($desktop) {
      margin-right: 30px;
    }
  }

  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}