@import "../variables";

.registration {
  display: none;
  position: fixed;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: #f2f2f2;
  overflow-y: auto;
  padding: 0 20px;

  &--open {
    display: block;
  }

  @media ($tablet) {
    background-color: #fff;
    position: absolute;
    right: 0;
    bottom: auto;
    left: auto;
    padding: 10px 20px 15px;
    width: 260px;
    top: 96px;
    border: 1px solid #d2d2d2;
    box-sizing: border-box;

    .step-back {
      display: none;
    }
  }
}

.registration__form-item {
  margin-bottom: 15px;

  .input--default {
    width: 100%;
  }

  &--check {
    padding: 0 10px;

    @media ($tablet) {
      padding: 0;
    }
  }

  &--button {
    .btn {
      width: 150px;
      margin: 0 auto;

      @media ($tablet) {
        width: 100%;
        height: 45px;
        margin-bottom: 20px;
      }
    }
  }
}