@import "../variables";

.filter {
  @media ($tablet) {
    width: 200px;
    margin-right: 30px;
    flex-shrink: 0;
  }

  @media ($desktop) {
    margin-right: 90px;
  }
}

.filter__count {
  margin: 0 0 5px;
  font-family: $fontRegular;
  font-size: 9px;
  color: #494949;

  @media ($tablet) {
    display: none;
  }
}

.filter__form {
  margin-bottom: 20px;

  &--settings {
    display: none;

    @media ($tablet) {
      display: block;
    }
  }

  &--open {
    display: block;
  }
}

.filter__item {
  @media ($tablet) {
    margin-bottom: 20px;
  }

  &--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .filter__label {
      width: 100%;
    }

    .select-wrapper {
      flex-grow: 1;
      margin-right: 2px;

      @media ($tablet) {
        margin-right: 0;
      }
    }
  }

  &--buttons {
    .btn {
      width: 100%;
      height: 35px;
      margin-bottom: 10px;

      &--default {
        @media ($desktop) {
          font-size: 11px;
        }
      }
    }
  }
}

.filter__label {
  font-family: $fontBold;
  font-size: 10px;
  color: #494949;
  display: block;
  margin-bottom: 5px;

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 14px;
    color: #06081e;
  }
}

.filter__select {
  width: 100%;
  font-family: $fontRegular;
  font-size: 11px;
  color: #fff;
  background-color: #00963f;
  height: 35px;
  padding: 0 30px 0 15px;

  option {
    &:checked {
      background-color: #00963f;
    }

    &:not(:checked) {
      background-color: #494949;
    }
  }
}

.filter__controls {
  width: 35px;
  height: 35px;

  @media ($tablet) {
    display: none;
  }
}

.filter__controls-svg {
  fill: #fff;
  width: 100%;
  height: 100%;
}

.filter__list {
  margin: 15px 0;
  padding: 0;
  list-style-type: none;

  &--color {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media ($tablet) {
      display: block;
    }

    .label--check::before {
      display: none;

      @media ($tablet) {
        display: block;
      }
    }
  }
}

.filter__list-item {
  margin-bottom: 10px;

  .input--check {
    &:checked + .label--check .filter__color {
      border-color: #00963f;
    }
  }
}

.filter__range-wrapper {
  .noUi-target {
    background: #a5a5a5;
    border-radius: 0;
    border: none;
    box-shadow: none;
    height: 4px;
    width: 95%;
    margin: 15px auto;
  }

  .noUi-connect {
    background: #00963f;
  }

  .noUi-handle {
    width: 12px;
    height: 12px;
    background-color: #00963f;
    box-shadow: none;
    border: 1px solid #aad5bc;
    border-radius: 100%;
    box-sizing: border-box;
    top: -4px;
    right: 0;

    &::before,
    &::after {
      display: none;
    }
  }
}

.filter__range-sum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 0;
  color: #494949;
}

.filter__range-input {
  width: 40%;
  background: transparent;
  border: 1px solid #aeaeae;
  box-sizing: border-box;
  height: 35px;
  padding: 0 15px;
  font-family: $fontRegular;
  font-size: 12px;
  color: #494949;

  &:focus {
    border-color: #00963f;
    box-shadow: inset 0 3px 7px 0 rgba(0, 0, 1, 0.3);
  }
}

.filter__color-text {
  display: none;

  @media ($tablet) {
    display: block;
    font-family: $fontRegular;
    font-size: 12px;
    color: #494949;
    margin-left: 13px;
  }
}

.filter__color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 35px;
  color: #fff;
  font-family: $fontBold;
  font-size: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &--white {
    background-color: #fff;
    color: #494949;
    border: 1px solid #a9a9a9;
  }

  @media ($tablet) {
    font-size: 0;
    width: 15px;
    height: 15px;
  }
}

html:not([dir="rtl"]) .noUi-horizontal .noUi-handle {
  right: -4px;
}