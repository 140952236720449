@import "../variables";

.profile {
  .title {
    @media ($tablet) {
      text-align: center;
      font-size: 17px;
    }
  }
}

.profile__item {
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  position: relative;

  &--top {
    padding-left: 10px;

    @media ($tablet) {
      padding-left: 0;
    }

    .profile__label {
      width: 15px;
      display: block;
      flex-shrink: 0;
      margin-right: 10px;

      @media ($tablet) {
        color: #494949;
        width: auto;
        font-size: 12px;
        margin-right: 0;
        margin-bottom: 10px;
        font-weight: 700;
        font-family: 'Open Sans', 'Arial', sans-serif;
      }
    }

    span {
      display: none;

      @media ($tablet) {
        display: block;
      }
    }
  }

  &--comment {
    @media ($tablet) {
      margin-top: 20px;
    }

    .profile__label {
      display: none;
    }
  }

  .input,
  .textarea {
    width: 100%;
  }

  .textarea {
    min-height: 120px;
  }
}

.profile__label {
  flex-shrink: 0;
  margin-right: 8px;
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;

  @media ($tablet) {
    color: #494949;
    width: auto;
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 10px;
    font-family: 'Open Sans', 'Arial', sans-serif;
  }
}

.profile__svg {
  fill: #00963e;

  &--name {
    width: 12px;
    height: 14px;
  }

  &--phone {
    width: 12px;
    height: 12px;
  }

  &--email {
    width: 13px;
    height: 11px;
  }

  &--skype {
    width: 12px;
    height: 12px;
  }
}

.profile__edit-svg {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 11px;
  right: 10px;
  fill: #a5a5a5;

  @media ($tablet) {
    width: 15px;
    height: 15px;
    top: 38px;
  }
}

.profile__done {
  width: 12px;
  height: 10px;
  fill: #00963e;
  position: absolute;
  top: 12px;
  right: 10px;

  @media ($tablet) {
    width: 15px;
    height: 13px;
    top: 39px;
  }
}

.profile__title {
  font-family: $fontRegular;
  font-size: 10px;
  color: #494949;
  margin: 20px 0 10px;

  @media ($tablet) {
    font-size: 17px;
    text-align: center;
    text-transform: uppercase;
    font-family: $fontBold;
    margin: 50px 0 25px;
  }
}

.profile__button {
  margin: 25px 0;

  .btn {
    width: 150px;
    margin: 0 auto;
  }
}

.profile__links {
  display: none;

  @media ($tablet) {
    display: flex;
    margin-bottom: 15px;
  }
}

.profile__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 35px;
  background-color: #d2d2d2;
  color: #494949;
  font-size: 12px;
  margin-right: 3px;

  &:hover,
  &--active {
    background-color: #00963f;
    color: #fff;
  }
}

.profile__top {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;

    .profile__item {
      width: 23%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .profile__svg {
      display: none;
    }
  }
}

.profile__company {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;

    .profile__item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }
}

.profile__company-wrapper {
  @media ($tablet) {
    width: 465px;
  }

  @media ($desktop) {
    width: 557px;
  }
}