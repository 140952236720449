@import "../variables";

.last {
  margin-bottom: 30px;
}

.last__slider {
  .catalog-item {
    border-color: transparent;
    box-shadow: none;

    @media ($desktop) {
      &:hover {
        border-color: #d1d1d2;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 1, 0.15);
      }
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    background: none;
    width: 16px;
    height: 16px;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev {
    left: 0;
  }
}

.last__arrow {
  width: 16px;
  height: 16px;
  fill: #00963f;

  &--right {
    transform: rotate(180deg);
  }
}