@import "../variables";

.category-item {
  box-shadow: 0 2px 17px 0 rgba(79, 79, 79, 0.51);
  position: relative;

  @media ($tablet) {
    width: 235px;
    box-shadow: none;
  }

  @media ($desktop) {
    width: 265px;
  }
}

.category-item__img {
  display: block;
  width: 100%;
}

.category-item__content {
  padding: 15px 25px;

  @media ($tablet) {
    padding: 15px 20px;
  }
}

.category-item__title {
  margin: 0 0 10px;
  font-size: 12px;
  font-weight: 600;
  color: #494949;
  text-transform: uppercase;

  @media ($tablet) {
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  }

  @media ($desktop) {
    font-size: 18px;
  }
}

.category-item__desc {
  font-size: 10px;
  color: #494949;
  margin: 0;
  width: 220px;

  @media ($tablet) {
    font-size: 11px;
    width: 170px;
  }

  @media ($desktop) {
    font-size: 12px;
    width: 190px;
  }
}

.category-item__link {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
