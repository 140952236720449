@import "../variables";

.catalog-wrapper {
  margin-bottom: 40px;
}

.catalog-wrapper__content {
  @media ($tablet) {
    display: flex;
    justify-content: space-between;
  }
}