@import "../variables";

.product {
  display: flex;
  flex-direction: column;

  @media ($tablet) {
    display: block;
  }

  @media ($desktop) {
    position: relative;
  }
}

.product__composition {
  font-size: 9px;
  color: #494949;
  margin: 0 0 7px;
  width: 100%;

  @media ($tablet) {
    width: auto;
    margin: 0;
    font-size: 12px;
  }
}

.product__composition-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  order: -1;

  @media ($tablet) {
    justify-content: flex-start;
    margin-bottom: 25px;
  }

  @media ($desktop) {
    padding-right: 255px;
    margin-bottom: 10px;
  }

  .title {
    display: none;

    @media ($tablet) {
      display: block;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

.product__sales-wrapper {
  display: none;

  @media ($tablet) {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 10px;

    .product__tip {
      margin-left: 0;
      margin-right: 25px;
    }
  }
}

.product__rating-star {
  width: 6px;
  height: 6px;
  fill: #00963f;
  margin-right: 2px;
}

.product__rating {
  display: flex;
  align-items: center;

  span {
    font-size: 8px;
    color: #00963f;
    margin-left: 5px;
  }

  @media ($tablet) {
    display: none;
  }
}

.product__view-count {
  display: flex;
  align-items: center;
  margin: 0;

  span {
    font-size: 8px;
    color: #494949;

    @media ($tablet) {
      font-size: 10px;
    }
  }

  @media ($tablet) {
    margin-left: 25px;
  }
}

.product__view-svg {
  width: 15px;
  height: 11px;
  margin-right: 5px;
  fill: #494949;

  @media ($tablet) {
    width: 19px;
    height: 15px;
  }
}

.product__article {
  display: block;
  width: 100%;
  font-size: 9px;
  color: #494949;
  order: -1;
  margin: 0 0 5px;

  @media ($tablet) {
    font-size: 12px;
    margin: 10px 0;
    order: initial;
  }
}

.product__slider {
  margin-bottom: 20px;
  position: relative;

  .swiper-wrapper {
    align-items: center;
    padding: 1px 0;
  }

  @media ($tablet) {
    width: 480px;
    float: left;
    margin-right: 35px;
    margin-bottom: 30px;
  }
}

.product__controls {
  .swiper-button-next,
  .swiper-button-prev {
    top: 40%;

    @media ($tablet) {
      top: auto;
      bottom: 25px;
      background-size: 20px 37px;
    }
  }

  .swiper-button-next {
    background-image: url('../img/svg/icon-right-arrow.svg');

    @media ($tablet) {
      right: -15px;
    }
  }

  .swiper-button-prev {
    background-image: url('../img/svg/icon-left-arrow.svg');

    @media ($tablet) {
      left: -25px;
    }
  }
}

.product__slider-item {
  img {
    display: block;
    width: 100%;
  }
}

.product__slider-thumb {
  border: 1px solid transparent;
  width: 24% !important;
  position: relative;

  img {
    display: block;
    width: 100%;
  }

  &.swiper-slide-thumb-active {
    border: 1px solid #e3e3e3;
  }

  &--360 {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255,255,255,.4) url('../img/svg/icon-360.svg') 50% 50% no-repeat;
      background-size: 40px auto;

      @media (min-width: 600px) {
        background-size: 80px auto;
      }
    }
  }
}

.product__content {
  @media ($desktop) {
    width: 185px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.product__form {
  @media ($tablet) {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  @media ($desktop) {
    flex-direction: column;
  }
}

.product__form-item {
  margin-bottom: 15px;

  @media ($tablet) {
    width: 45%;
    margin-bottom: 25px;
  }

  @media ($desktop) {
    width: 100%;
  }

  &--price {
    display: flex;
    align-items: flex-end;

    @media ($tablet) {
      order: 3;
      flex-wrap: wrap;

      .product__tip {
        display: none;
      }
    }

    .product__label {
      display: none;

      @media ($tablet) {
        display: block;
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }

  &--count {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .product__label {
      margin-bottom: 0;

      @media ($tablet) {
        margin-bottom: 20px;
        display: block;
      }
    }

    @media ($tablet) {
      order: 2;
      margin-bottom: 0;
      display: block;
    }

    @media ($desktop) {
      margin-bottom: 25px;
    }
  }

  &--button {
    margin-top: 35px;

    .btn {
      width: 100%;
    }

    @media ($tablet) {
      order: 5;
      margin-top: 0;
      margin-bottom: 10px;
    }
  }

  &--size {
    @media ($tablet) {
      order: 1;
    }
  }

  &--color {
    @media ($tablet) {
      order: 4;

      .product__label {
        margin-bottom: 20px;
      }
    }

    @media ($desktop) {
      order: -1;
    }
  }
}

.product__now {
  font-size: 17px;
  font-weight: 700;
  color: #494949;
  letter-spacing: 1.6px;

  @media ($tablet) {
    font-size: 25px;
    letter-spacing: normal;
  }
}

.product__old {
  font-size: 12px;
  font-weight: 600;
  color: #a5a5a5;
  letter-spacing: 1.6px;
  text-decoration: line-through;
  margin-left: 7px;

  @media ($tablet) {
    font-size: 16px;
    margin-left: 10px;
  }
}

.product__tip {
  padding: 4px 7px 5px;
  font-size: 10px;
  font-weight: 600;
  position: relative;
  text-transform: uppercase;
  margin-left: 25px;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    display: block;
    position: absolute;
    top: 0;
    left: -10px;
  }

  &--sale {
    color: #fff;
    background-color: #ff6973;

    &::before {
      border-right-color: #ff6973;
    }
  }

  &--new {
    color: #fff;
    background-color: #009ee3;

    &::before {
      border-right-color: #009ee3;
    }
  }
}

.product__label {
  margin: 0 0 5px;
  font-family: $fontBold;
  font-size: 10px;
  color: #494949;

  @media ($tablet) {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: 12px;
  }
}

.product__list {
  margin: 15px 0;
  padding: 0;
  list-style-type: none;

  &--color {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .label--check::before {
      display: none;
    }

    @media ($tablet) {
      margin: 0;
    }
  }
}

.product__list-item {
  margin-bottom: 10px;
  margin-right: 5px;

  .input--check {
    &:checked + .label--check .product__color {
      border-color: #00963f;
    }
  }
}

.product__color-text {
  display: none;
}

.product__color {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 35px;
  color: #fff;
  font-family: $fontBold;
  font-size: 8px;
  box-sizing: border-box;
  border: 1px solid transparent;

  &--white {
    background-color: #fff;
    color: #494949;
    border: 1px solid #a9a9a9;
  }

  @media ($tablet) {
    font-size: 0;
    width: 33px;
    height: 24px;
    border-radius: 2px;
  }
}

.product__count-wrapper {
  display: flex;
  align-items: center;
  margin-left: 16px;

  @media ($tablet) {
    margin-left: 0;
  }
}

.product__count-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 20px;
  font-size: 16px;
  color: #494949;
  background-color: #d2d2d2;
  margin: 0 4px;
  box-sizing: border-box;
  border: 1px solid #b4b4b4;

  @media ($tablet) {
    width: 33px;
    height: 24px;
  }
}

.product__count-input {
  border: 1px solid #b4b4b4;
  width: 28px;
  height: 20px;
  font-size: 12px;
  color: #06081e;

  @media ($tablet) {
    width: 33px;
    height: 24px;
    text-align: center;
  }
}

.product__form-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  .product__label {
    margin-bottom: 0;
  }

  @media ($tablet) {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.product__size-link {
  font-size: 9px;
  color: #494949;
  display: inline-block;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #494949;
  line-height: 12px;

  @media ($tablet) {
    font-size: 12px;
  }
}

.product__form-size {
  margin-right: 5px;

  .label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    height: 27px;
    border: 1px solid #b6b6b6;
    border-radius: 2px;
    font-size: 13px;
    text-transform: uppercase;
    color: #06081e;

    &::before {
      display: none;
    }

    @media ($tablet) {
      width: 33px;
      height: 24px;
      font-size: 12px;
      box-sizing: border-box;
    }
  }

  .input:checked + .label {
    background-color: #00963f;
    color: #fff;
    border-color: #66c08c;
  }
}

.product__cart-svg {
  width: 15px;
  height: 14px;
  fill: #fff;
  margin-left: 10px;
}

.product__about {
  @media ($tablet) {
    min-height: 110px;
  }

  @media ($desktop) {
    padding-right: 255px;
    min-height: 380px;
  }

  b {
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    color: #494949;
    margin: 0 0 8px;

    @media ($tablet) {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: 400;
      letter-spacing: 1.6px;
      margin: 15px 0;
    }
  }

  p {
    font-size: 9px;
    color: #494949;
    margin: 0 0 8px;

    @media ($tablet) {
      font-size: 12px;
    }
  }

  ul {
    margin: 8px 0;
    padding: 0;
    font-size: 9px;
    color: #494949;
    list-style-type: none;

    li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 100%;
        background-color: #00963f;
        flex-shrink: 0;
        margin-right: 7px;
      }
    }

    @media ($tablet) {
      font-size: 12px;
    }
  }
}