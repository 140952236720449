@import "../variables";

.footer {
  background: #fbfbfb url('../img/footer-bg.jpg') 50% 0 no-repeat;
  background-size: cover;
  padding: 215px 0 15px;

  @media ($tablet) {
    background-color: #fff;
    background-image: url('../img/footer-bg-tablet.jpg');
    padding-bottom: 60px;
  }

  @media ($desktop) {
    background-image: url('../img/footer-bg-desktop.jpg');
    padding-top: 350px;
  }
}

.footer__wrapper {
  @media ($tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px 0 90px;
    position: relative;
  }

  @media ($desktop) {
    padding-right: 10px;
  }
}

.footer-nav-wrapper {
  margin-bottom: 14px;

  @media ($tablet) {
    margin-bottom: 0;
  }
}

.footer__nav {
  margin: 0 0 25px;
  padding: 0;
  list-style-type: none;

  @media ($tablet) {
    margin-bottom: 15px;
    column-count: 2;
  }
}

.footer__nav-item {
  &:not(:first-child) {
    margin-top: 15px;

    @media ($tablet) {
      margin-top: 5px;
    }
  }
}

.footer__link {
  font-family: $fontRegular;
  font-size: 10px;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;

  @media ($desktop) {
    font-size: 14px;
  }
}

.footer__title {
  margin: 0 0 10px;
  font-size: 10px;
  color: #fff;
  text-transform: uppercase;

  @media ($desktop) {
    font-size: 14px;
  }
}

.footer__social-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
}

.footer__social-item {
  margin-right: 10px;
}

.footer__social-link {
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #fff;
}

.footer__social-fb {
  width: 12px;
  height: 14px;
  fill: #395599;
}

.footer__social-vk {
  width: 19px;
  height: 15px;
  fill: #5181b8;
}

.footer__contact {
  margin-bottom: 20px;

  @media ($tablet) {
    margin-bottom: 0;
    order: 3;
    width: 265px;
  }

  @media ($desktop) {
    width: 360px;
  }
}

.footer__phone {
  margin: 0 0 10px;
  display: flex;
  align-items: center;
}

.footer__phone-link {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  text-decoration: none;

  @media ($desktop) {
    font-size: 14px;
  }
}

.footer__phone-svg {
  width: 9px;
  height: 9px;
  fill: #fff;
  margin-right: 8px;

  @media ($desktop) {
    width: 12px;
    height: 12px;
  }
}

.footer__address {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  margin: 0 0 10px;
  display: flex;
  align-items: center;

  @media ($desktop) {
    font-size: 14px;
  }
}

.footer__address-svg {
  width: 18px;
  height: 20px;
  fill: #fff;
  margin-right: 7px;

  @media ($desktop) {
    width: 28px;
    height: 30px;
  }
}

.footer__logo {
  text-align: center;

  @media ($tablet) {
    margin-left: 30px;
  }
}

.footer__logo-link {
  display: inline-block;
  width: 123px;

  img {
    width: 100%;
  }

  @media ($tablet) {
    width: 211px;
  }

  @media ($desktop) {
    width: 285px;
  }
}