@import "../variables";

.auth-tab {
  display: none;

  @media ($tablet) {
    display: block;
    margin-bottom: 20px;
  }
}

.auth-tab__link {
  font-family: $fontRegular;
  font-size: 12px;
  text-decoration: none;
  color: #494949;
  padding-right: 8px;

  &:not(:first-child) {
    border-left: 1px solid #494949;
    padding-left: 8px;
  }

  &--active {
    color: #00963f;
  }
}