@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap&subset=cyrillic');

@font-face {
  font-family: 'proxima_novabold';
  src: url('../fonts/proximanova-bold-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'proxima_novaregular';
  src: url('../fonts/proximanova-reg-webfont.woff2') format('woff2'),
  url('../fonts/proximanova-reg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}